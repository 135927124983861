import * as React from 'react';
import { TReactFCP, TReactFCR } from '../lib/types/utils';
import { Page } from '../components/app/Page';
import { UseStyles } from '../lib/types/mui';
import { Theme, makeStyles } from '@material-ui/core';
import { Typography } from '../components/Typography';
import { Link } from '../components/Link';

type ClassKey = 'main' | 'greeting' | 'name' | 'headline';
const useStyles = makeStyles<Theme, ClassKey>((theme: Theme) => ({
    main: {
        flexGrow: 1,
        display: 'flex',
        flexDirection: 'column',
        flexWrap: 'nowrap',
        justifyContent: 'center',
        padding: theme.spacing(4),
        maxWidth: '45em',
        margin: '0 auto',
        marginBottom: theme.spacing(6)
    },
    greeting: {
        ...theme.typography.sizing(2),
        letterSpacing: theme.typography.overline.letterSpacing,
        color: theme.palette.custom.pages.home.greeting,
        fontWeight: theme.typography.fontWeightBold,
        marginBottom: theme.spacing(4)
    },
    name: { ...theme.typography.sizing(11, 'heading') },
    headline: { ...theme.typography.sizing(8, 'heading') }
}));

export function Home(props: TReactFCP): TReactFCR {
    const styles: UseStyles<ClassKey> = useStyles();
    return (
        // SEO not needed for '/' because its automatically handled by the `Page` component scaffolding. This ensures
        // that all pages have the default values if they aren't provided
        <main className={styles.main}>
            <Typography className={styles.greeting} variant='overline'>Hi, my name is </Typography>
            <Typography className={styles.name} variant='h1'>John Richter</Typography>
            <Typography className={styles.headline} variant='h2' gutterBottom color='light'>
                I actually go <i>fast</i> <s>and break things</s>
            </Typography>
            <Typography variant='subtitle1' paragraph regular>
                {`I'm `}
                <Link variant='external' to='https://paddock.tech' bold>bootstrapping a SaaS business</Link>
                {` in a notoriously difficult niche market — amateur motorsports. You can follow along in my `}
                <Link variant='internal' to='/blog/' bold>blog</Link>
                {` where I talk about engineering, motorsports, and entrepreneurship or try out the `}
                <Link variant='external' to='https://app.paddock.tech' bold>Paddock Tech app</Link>
                {` right now!`}
            </Typography>
            <Typography variant='subtitle1' paragraph regular>
                When it's not snowing you can find me at race tracks on the East Coast of the USA
                coaching drivers from the right seat and getting into shenanigans with{` `}
                <Link variant='external' to='https://gtmotorsports.org' bold>Gran Touring Motorsports</Link> at{` `}
                <Link variant='external' to='https://en.wikipedia.org/wiki/High_Performance_Driver_Education' bold>
                    HPDE
                </Link>
                {` events. Vetting me? Checkout my `}
                <Link variant='internal' to='/driving/' bold>instructor and driver qualifications</Link>
                {` — I like to `}<i>track</i>{` 😏 my experience.`}
            </Typography>
            <span><Link variant='internal' to='/about/' bold>Learn more about me -></Link></span>
        </main>
    );
}

export default (props: TReactFCP): TReactFCR => <Page component={Home} cprops={props} />;
